import { ReactElement } from 'react';
import { withApollo } from 'next-apollo';
import dynamic from 'next/dynamic';
import type { AppProps } from 'next/app';
import { ApolloProvider } from '@apollo/client';
import { DefaultSeo } from 'next-seo';
import AppContainer from 'containers/AppContainer/AppContainer';
import Head from 'next/head';
import { apolloClient } from 'shopify/shopify';
import GlobalContextProvider from 'context/GlobalContext';
import seo from 'config/seo';

import '@fontsource/quicksand/400.css';
import '@fontsource/quicksand/600.css';
import 'tailwindcss/tailwind.css';
import CheckoutContextProvider from 'context/CheckoutContext';

const FirebaseProvider = dynamic(() => import('@firebase/Provider'));

export const apolloWith = withApollo(apolloClient);

const App = (props: AppProps): ReactElement => (
  <>
    <DefaultSeo {...seo} />

    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
    </Head>

    <ApolloProvider client={apolloClient}>
      <FirebaseProvider>
        <GlobalContextProvider>
          <CheckoutContextProvider>
            <AppContainer {...props} />
          </CheckoutContextProvider>
        </GlobalContextProvider>
      </FirebaseProvider>
    </ApolloProvider>
  </>
);

export default App;
