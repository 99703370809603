import { createContext, ReactElement, ReactNode } from 'react';
import { useCycle } from 'framer-motion';

type GlobalContextType = {
  cartOpen: boolean;
  navOpen: boolean;
  toggleCartOpen: () => void;
  toggleNavOpen: () => void;
};

export const GlobalContext = createContext<GlobalContextType>({
  cartOpen: false,
  navOpen: false,
  toggleCartOpen: () => '',
  toggleNavOpen: () => '',
});

const GlobalContextProvider = ({
  children,
}: GlobalContextProviderType): ReactElement => {
  const [cartOpen, toggleCartOpen] = useCycle(false, true);
  const [navOpen, toggleNavOpen] = useCycle(false, true);

  return (
    <GlobalContext.Provider
      value={{ cartOpen, navOpen, toggleCartOpen, toggleNavOpen }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

type GlobalContextProviderType = {
  children: ReactNode;
};

export default GlobalContextProvider;
