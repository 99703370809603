import {
  createContext,
  ReactElement,
  ReactNode,
  useState,
  useEffect,
  useCallback,
  useContext,
} from 'react';
import getCheckout, {
  addProductToCart,
  removeProductFromCart,
} from 'checkout/checkout';
import { GlobalContext } from './GlobalContext';

type CheckoutContextType = {
  checkout: Checkout;
  checkoutLoading: boolean;
  addToCart: (variantId: string, quantity: number) => void;
  removeFromCart: (variantId: string) => void;
  addingProductToCart: boolean;
};

export const CheckoutContext = createContext<CheckoutContextType>({
  checkout: {},
  checkoutLoading: true,
  addToCart: () => '',
  removeFromCart: () => '',
  addingProductToCart: false,
});

const CheckoutContextProvider = ({
  children,
}: CheckoutContextProviderType): ReactElement => {
  const [checkoutLoading, setCheckoutLoading] = useState(true);
  const [checkout, setCheckout] = useState<Checkout | Record<string, never>>();
  const [addingProductToCart, setAddingProductToCart] = useState(false);

  const { toggleCartOpen } = useContext(GlobalContext);

  useEffect(() => {
    const fetchCheckout = async () => {
      const result = await getCheckout();

      setCheckout(result);
      setCheckoutLoading(false);
    };

    fetchCheckout();
  }, []);

  const addToCart = useCallback(
    async (variantId: string, quantity: number) => {
      setAddingProductToCart(true);

      try {
        const newCheckout = await addProductToCart(
          checkout && checkout.id,
          variantId,
          quantity,
        );

        setCheckout(JSON.parse(JSON.stringify(newCheckout)));

        toggleCartOpen();
      } catch {
        // TODO: error alert
      } finally {
        setAddingProductToCart(false);
      }
    },
    [checkout],
  );

  const removeFromCart = useCallback(
    async (variantId: string) => {
      try {
        const newCheckout = await removeProductFromCart(
          checkout && checkout.id,
          variantId,
        );

        setCheckout(JSON.parse(JSON.stringify(newCheckout)));
      } catch {
        // TODO: error alert
      }
    },
    [checkout],
  );

  return (
    <CheckoutContext.Provider
      value={{
        checkout,
        checkoutLoading,
        addToCart,
        removeFromCart,
        addingProductToCart,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};

type CheckoutContextProviderType = {
  children: ReactNode;
};

export default CheckoutContextProvider;
