import { ApolloClient, InMemoryCache } from '@apollo/client';
import Client from 'shopify-buy';

export const apolloClient = new ApolloClient({
  uri: 'https://craftypartybox.co.uk/api/2020-10/graphql.json',
  cache: new InMemoryCache(),
  headers: {
    'X-Shopify-Storefront-Access-Token': 'c77338ca46966d4f895d8fc3771a21b6',
  },
});

const client = Client.buildClient({
  domain: 'craftypartybox.co.uk',
  storefrontAccessToken: 'c77338ca46966d4f895d8fc3771a21b6',
});

export default client;
