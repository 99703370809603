import getPageTitle from 'utils/get-page-title';

const name = 'Crafty Party Box';

const title = getPageTitle('Crafty Party Box');

const description =
  'Our kids craft boxes are great for your child’s party activity with their friends but they’re also great individually for a rainy day or just for some fun family time.';

export default {
  title,
  description,
  openGraph: {
    title,
    description,
    type: 'website',
    locale: 'en_GB',
    url: 'https://craftypartybox.co.uk',
    site_name: name,
    images: [
      {
        url: '/card.png',
        width: 800,
        height: 600,
        alt: title,
      },
    ],
  },
  twitter: {
    handle: '@craftypartybox',
    site: '@craftypartybox',
    cardType: 'summary_large_image',
  },
};
