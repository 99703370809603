import { ReactElement, useContext } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import Logo from 'public/logo.svg';
import DarkLogo from 'public/logo-dark.svg';
import { BiBasket } from 'react-icons/bi';
import { HiOutlineMenuAlt2 } from 'react-icons/hi';
import { GlobalContext } from 'context/GlobalContext';

const CartSide = dynamic(() => import('./components/CartSide'));
const NavigationSide = dynamic(() => import('./components/NavigationSide'));

const Header = (): ReactElement => {
  const { cartOpen, navOpen, toggleCartOpen, toggleNavOpen } = useContext(
    GlobalContext,
  );

  const { asPath } = useRouter();

  return (
    <>
      <div className="py-2 sticky top-0 z-30 bg-white dark:bg-dark flex content-between items-center">
        <div className="ml-4 flex flex-1">
          <button
            className="bg-transparent border-none my-2 dark:text-white cursor-pointer"
            onClick={toggleNavOpen}
            type="button"
          >
            <HiOutlineMenuAlt2 size="30" />
          </button>
        </div>

        <div className="relative dark:hidden cursor-pointer">
          <Link href="/">
            <Image src={Logo} alt="Crafty Party Box" width="150" height="70" />
          </Link>
        </div>

        <div className="relative hidden dark:block cursor-pointer">
          <Link href="/">
            <Image
              src={DarkLogo}
              alt="Crafty Party Box"
              width="150"
              height="70"
            />
          </Link>
        </div>

        <div className="mr-4 flex flex-1 justify-end">
          {/* <Link href="/account">
            <IconButton>
              <BiUser size="30" />
            </IconButton>
          </Link> */}
          <button
            className="bg-transparent border-none my-2 dark:text-white cursor-pointer"
            onClick={toggleCartOpen}
            type="button"
          >
            <BiBasket size="30" />
          </button>
        </div>
      </div>

      <CartSide cartOpen={cartOpen} toggleCart={toggleCartOpen} />

      <NavigationSide
        current={asPath}
        navOpen={navOpen}
        toggleNav={toggleNavOpen}
      />
    </>
  );
};

export default Header;
