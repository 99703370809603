import { ReactElement, useContext } from 'react';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { AnimatePresence, motion } from 'framer-motion';
import Header from 'components/Header/Header';
import { GlobalContext } from 'context/GlobalContext';

const Footer = dynamic(() => import('components/Footer/Footer'));

const AppContainer = ({ Component, pageProps }: AppProps): ReactElement => {
  const { cartOpen, navOpen, toggleNavOpen, toggleCartOpen } = useContext(
    GlobalContext,
  );

  const toggleNavCart = () => {
    if (navOpen) {
      toggleNavOpen();
    }

    if (cartOpen) {
      toggleCartOpen();
    }
  };

  return (
    <div className="dark:bg-dark dark:text-white flex flex-col justify-evenly min-h-screen font-display">
      <Header />

      {(cartOpen || navOpen) && (
        <AnimatePresence>
          <motion.div
            className="fixed top-0 bottom-0 left-0 right-0 bg-white dark:bg-dark z-40"
            key="overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.8 }}
            exit={{ opacity: 0 }}
            onClick={toggleNavCart}
          />
        </AnimatePresence>
      )}

      <Component {...pageProps} />

      <Footer />
    </div>
  );
};

export default AppContainer;
