import cookie from 'react-cookies';
import client from 'shopify/shopify';

const getCheckout = async (): Promise<Checkout> => {
  try {
    const checkoutId = cookie.load('checkoutId');

    if (!checkoutId) {
      const { data } = client.checkout.create();

      const { checkout } = data.checkoutCreate;

      cookie.save('checkoutId', checkout.id, {
        path: '/',
      });

      return JSON.parse(JSON.stringify(checkout));
    }

    const checkout = await client.checkout.fetch(checkoutId);

    return JSON.parse(JSON.stringify(checkout));
  } catch (error) {
    // TODO: error alert
    return {};
  }
};

export const addProductToCart = async (
  checkoutId: string,
  variantId: string,
  quantity: number,
): Promise<void> =>
  client.checkout.addLineItems(checkoutId, [{ variantId, quantity }]);

export const removeProductFromCart = async (
  checkoutId: string,
  variantId: string,
): Promise<void> => client.checkout.removeLineItems(checkoutId, [variantId]);

export default getCheckout;
